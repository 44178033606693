<template>
  <div
    class="post-field"
    :class="{ 'has-attachments': form.attachments.length }"
  >
    <textarea-autosize
      ref="textarea"
      v-model="form.message"
      :placeholder="placeholder"
      :disabled="disabled"
      :min-height="minHeight"
      :max-height="maxHeight"
      rows="1"
      class="textarea"
      @keyup.up.native.prevent.exact="$emit('onUpArrow')"
      @keydown.enter.exact.native.prevent
      @keyup.enter.exact.native.prevent="$emit('onEnter')"
      @keyup.esc.native.prevent.exact="$emit('onEscape')"
    />
    <file-selector
      ref="fileSelector"
      v-model="form.attachments"
      :max-files="5"
    />
  </div>
</template>

<script>
export default {
  name: "PostField",
  props: {
    message: {
      type: String,
      default: ""
    },
    attachments: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "How can we help?"
    },
    minHeight: {
      type: Number,
      default: 0
    },
    maxHeight: {
      type: Number,
      default: 450
    }
  },
  data() {
    return {
      form: {
        message: this.message,
        attachments: this.attachments
      }
    };
  },
  watch: {
    "form.message"(value) {
      this.$emit("message", this.$_.trim(value));
    },
    "form.attachments"(value) {
      this.$emit("attachments", value);
    }
  },
  methods: {
    attachFile(file = null) {
      if (this.$_.isEmpty(this.$refs.fileSelector)) return;
      if (file) return this.$refs.fileSelector.addFile(file);
      this.$refs.fileSelector.openFileDialog();
    },
    reset() {
      this.form.message = "";
      this.$refs.fileSelector.clear();
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.textarea.$el.focus();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.post-field {
  textarea {
    padding: 1rem;
  }
  &.has-attachments {
    > textarea {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    /deep/ .file-selector ul {
      border-top-width: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &.is-note textarea {
    background-color: rgba($warning, 0.1);
    &:focus {
      border-color: $warning;
      box-shadow: 0 0 0 0.125em rgba($warning, 0.1);
    }
  }
}
</style>
